/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { BaseObservable } from "../../observer";
import { AuthListener, AuthWrapper } from "../../auth";
export class ReceiverManager extends BaseObservable {
    constructor() {
        super();
        this.standardReceivers = [];
        this.customReceivers = new Set();
        this.authHandler = (event) => __awaiter(this, void 0, void 0, function* () {
            if (event === "SignedIn") {
                this.standardReceivers = yield ReceiverManager.getStandardReceivers();
                this.notifyReceiversChanged();
            }
            else if (event === "SignedOut") {
                this.standardReceivers = [];
                this.customReceivers = new Set();
                this.notifyReceiversChanged();
            }
        });
        this.authListener = new AuthListener(this.authHandler, true);
    }
    static get instance() {
        if (!ReceiverManager.__instance) {
            ReceiverManager.__instance = new ReceiverManager();
        }
        return ReceiverManager.__instance;
    }
    static getStandardReceivers() {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            const userCansees = (_b = (_a = (yield AuthWrapper.getCurrentAuthenticatedUserClaims())) === null || _a === void 0 ? void 0 : _a.canSee) !== null && _b !== void 0 ? _b : [];
            const currentUserId = (_c = (yield AuthWrapper.getCurrentAuthenticatedUser())) === null || _c === void 0 ? void 0 : _c.attributes.sub;
            //no point subscribing to user id, backend never sends notifys to it so let's filter it out.
            const filteredUserCansees = userCansees.filter((canSee) => canSee !== currentUserId);
            return filteredUserCansees;
        });
    }
    // override
    addObserver(observer) {
        super.addObserver(observer);
        observer.onReceiversChanged(this.getFullReceiverList());
    }
    addReceivers(...receivers) {
        const notify = receivers
            // the order of the OR statements are important due to short-circuit evaluation
            .reduce((result, receiver) => this.addCustomReceiver(receiver) || result, false);
        if (notify)
            this.notifyReceiversChanged();
    }
    removeReceivers(...receivers) {
        const notify = receivers
            // the order of the OR statements are important due to short-circuit evaluation
            .reduce((result, receiver) => this.removeCustomReceiver(receiver) || result, false);
        if (notify)
            this.notifyReceiversChanged();
    }
    replaceReceivers({ toRemove = [], toAdd = [] }) {
        // remove replicated receivers - no need to remove and add them back again
        const add = toAdd.filter((a) => !toRemove.includes(a));
        const remove = toRemove.filter((a) => !toAdd.includes(a));
        // the order of the OR statements are important due to short-circuit evaluation
        let notify = add.reduce((result, receiver) => this.addCustomReceiver(receiver) || result, false);
        notify = remove.reduce((result, receiver) => this.removeCustomReceiver(receiver) || result, notify);
        if (notify)
            this.notifyReceiversChanged();
    }
    forceStandardReceiverRefresh() {
        return __awaiter(this, void 0, void 0, function* () {
            this.standardReceivers = yield ReceiverManager.getStandardReceivers();
            this.notifyReceiversChanged();
        });
    }
    addCustomReceiver(receiver) {
        if (this.customReceivers.has(receiver))
            return false;
        if (this.standardReceivers.includes(receiver))
            return false;
        this.customReceivers.add(receiver);
        return true;
    }
    removeCustomReceiver(receiver) {
        return this.customReceivers.delete(receiver);
    }
    notifyReceiversChanged() {
        const receivers = this.getFullReceiverList();
        console.log("Notifying receiver list change to " + receivers.toString());
        this.notifyAction((observer) => observer.onReceiversChanged(receivers));
    }
    getFullReceiverList() {
        return [...this.standardReceivers, ...this.customReceivers];
    }
}
