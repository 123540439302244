/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, Fragment, ReactNode } from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import StopIcon from "@material-ui/icons/Stop";
import AddIcon from "@material-ui/icons/Add";
import PlayIcon from "@material-ui/icons/PlayArrow";
import RefreshIcon from "@material-ui/icons/Refresh";
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions, SelectableRows } from "mui-datatables";
import { IconButton, Typography } from "@material-ui/core";
import ConfirmationDialog from "../../../ui/confirmation-dialog";
import Loader from "../../../ui/loader";
import AddBatchUpdatePopup from "./add-batch-update-popup";
import { translations } from "../../../../generated/translationHelper";
import {
  AuthWrapper,
  EventDefinition,
  Organization,
  OtaBatchUpdateState,
  OtaSubSessionStatus,
  OtaManager,
  OtaUpdateObserver,
  OtaState,
} from "@sade/data-access";
import { Maybe } from "@sade/data-access/lib/common/Utils";
import SubSessionDetails from "./subSession-details-popup";
import ErrorDialog from "../../../ui/error-dialog";

type OtaExecutionsTableCell = string | number | StopCell | JSX.Element;
type OtaExecutionsTableRow = OtaExecutionsTableCell[];

interface Props {
  organization: Organization;
}

interface State {
  loading: boolean;
  eventDefinitions: EventDefinition[];
  tableData: OtaExecutionsTableRow[];
  stopExecutionAction?: StopCell;
  errorMsg?: string;
  showAddBatchUpdatePopup: boolean;
  execDetails: string;
}

interface StopCell {
  execution: OtaSubSessionStatus;
}

export default class OtaManagement extends Component<Props, State> implements OtaUpdateObserver {
  private readonly tableColumns: MUIDataTableColumnDef[] = [
    {
      name: "id",
      label: "OTA Batch ID",
      options: {
        sort: true,
        customBodyRender: (id: string): ReactNode => {
          return (
            <div
              onClick={async (): Promise<void> => {
                this.setState({ execDetails: id });
              }}
            >
              <u>{id}</u>
            </div>
          );
        },
      },
    },
    {
      name: "batchid",
      label: "Batch #",
      options: {
        sort: true,
      },
    },
    {
      name: "deviceType",
      label: translations.admin.texts.deviceType(),
      options: {
        sort: true,
      },
    },
    {
      name: "firmware",
      label: translations.admin.texts.firmware(),
      options: {
        sort: true,
      },
    },
    {
      name: "createdAt",
      label: translations.admin.texts.createdAt(),
      options: {
        sort: true,
        customBodyRender: (createdAt: number): string => {
          return new Date(createdAt).toUTCString();
        },
      },
    },
    {
      name: "status",
      label: translations.common.texts.status(),
      options: {
        sort: true,
      },
    },
    {
      name: "idle",
      label: translations.admin.texts.idle(),
      options: {
        sort: true,
      },
    },
    {
      name: "inprogress",
      label: translations.admin.texts.inprogress(),
      options: {
        sort: true,
      },
    },
    {
      name: "failed",
      label: translations.admin.texts.failed(),
      options: {
        sort: true,
      },
      // TODO: add customBodYRender to show an button to open failed devices list
    },
    {
      name: "done",
      label: translations.admin.texts.done(),
      options: {
        sort: true,
      },
    },
    {
      name: "latestupdate",
      label: translations.admin.texts.updatedDate(),
      options: {
        sort: true,
        customBodyRender: (updatedAt: number): string => {
          return new Date(updatedAt).toUTCString();
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (stop: StopCell): ReactNode => {
          if (
            stop.execution.subSessionStatus === "IN_PROGRESS" ||
            stop.execution.subSessionStatus === "WAITING" ||
            stop.execution.subSessionStatus === "STARTED"
          ) {
            return (
              <div
                onClick={async (): Promise<void> => {
                  this.setState({ stopExecutionAction: { execution: stop.execution } });
                }}
              >
                <IconButton
                  title={translations.common.buttons.stop()}
                  style={{ backgroundColor: "#e6e6e6" }}
                  size="small"
                >
                  <StopIcon />
                </IconButton>
              </div>
            );
          } else if (stop.execution.runnable) {
            return (
              <div
                onClick={async (): Promise<void> => {
                  this.setState({ loading: true });
                  this.startOtaBatchOnClick(stop.execution.subSessionId);
                }}
              >
                <IconButton
                  title={translations.common.buttons.start()}
                  style={{ backgroundColor: "#e6e6e6" }}
                  size="small"
                >
                  <PlayIcon />
                </IconButton>
              </div>
            );
          }
        },
      },
    },
  ];

  private readonly tableOptions: MUIDataTableOptions = {
    filterType: "checkbox",
    selectableRows: "none" as SelectableRows,
    selectableRowsOnClick: false,
    search: false,
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectToolbarPlacement: "none",
    textLabels: {
      body: {
        noMatch: translations.admin.texts.noUpdateExecutionsAvailable(),
        toolTip: translations.common.texts.sort(),
      },
      pagination: {
        next: translations.common.buttons.nextPage(),
        previous: translations.common.buttons.previousPage(),
        rowsPerPage: translations.common.inputs.rowsPerPage(),
        displayRows: translations.common.texts.of(),
      },
    },
  };

  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      eventDefinitions: [],
      tableData: [],
      showAddBatchUpdatePopup: false,
      execDetails: "",
    };
  }

  private async getCurrentUserOrganization(): Promise<Maybe<string>> {
    const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();
    return claims?.homeOrganizationId;
  }

  public async componentDidMount(): Promise<void> {
    await OtaManager.getInstance().init(await this.getCurrentUserOrganization());
    await this.performLoad(this.updateTableData());
    OtaManager.getInstance().addObserver(this);
  }

  public async componentWillUnmount(): Promise<void> {
    OtaManager.getInstance().removeObserver(this);
    await OtaManager.getInstance().uninit();
  }

  public async onOtaBatchUpdateState(_state: OtaBatchUpdateState): Promise<void> {
    this.updateTableData(true);
  }

  // find the correct item in tableData and set runnable to false, to remove play-icon immeadiately
  private async removeRunnableStatus(subSessionId: string): Promise<OtaExecutionsTableRow[]> {
    const data = this.state.tableData.map((row: OtaExecutionsTableRow) => {
      const exec = (row[11] as StopCell).execution;
      if (exec.subSessionId === subSessionId) {
        exec.runnable = false;
      }
      (row[11] as StopCell).execution = exec;
      return row;
    });

    return data;
  }

  public async startOtaBatchOnClick(subSessionId?: string): Promise<void> {
    if (subSessionId) {
      // const otaBatchId = subSessionId.split("-").slice(0, -1).join("-");

      const newData = await this.removeRunnableStatus(subSessionId);

      this.setState({ tableData: newData });

      OtaManager.getInstance()
        .startOtaBatch(subSessionId)
        .then(() => {
          this.performLoad(this.updateTableData(true));
        })
        .catch((error: Error) => {
          console.error("startOtaBatch", error);
          this.setState({
            errorMsg: `${translations.admin.texts.failedToStartOtaBatch()}`,
          });
        });
    } else {
      console.error("otaBatchId not found");
    }
  }

  private async performLoad(...promises: Promise<void>[]): Promise<void> {
    this.setState({ loading: true });

    try {
      await Promise.all(promises);
    } catch (err) {
      console.error("performLoad", err);
    }
    this.setState({ loading: false });
  }

  private async getOtaBatchSubSessions(forceUpdate?: boolean): Promise<OtaSubSessionStatus[]> {
    return OtaManager.getInstance().getOtaBatchSubSessions(forceUpdate);
  }

  private async updateTableData(forceUpdate?: boolean): Promise<void> {
    const otaExecutions: OtaSubSessionStatus[] = await this.getOtaBatchSubSessions(forceUpdate);

    const tableData: OtaExecutionsTableRow[] = otaExecutions.map((execution) => {
      //console.log("execution", execution); // TODO: remove before PR

      let status = "";
      if (execution?.subSessionStatus === OtaState.Waiting) {
        const waitUntil = execution.waitUntil ?? "0";
        if (waitUntil === "0") {
          status = "wait missing";
        } else {
          let delta = (new Date(waitUntil).getTime() - Date.now()) / 1000;
          const prefix: string = delta < 0 ? "overdue" : OtaState.Waiting;
          if (delta < 0) {
            delta = (Date.now() - new Date(waitUntil).getTime()) / 1000;
          }
          // format delta to hours, minutes and seconds
          const hours = Math.floor(delta / 3600);
          const hoursString = hours > 0 ? `${hours}h ` : "";
          delta -= hours * 3600;
          const minutes = Math.floor(delta / 60);
          const minutesString = minutes > 0 ? `${minutes}m ` : "";
          delta -= minutes * 60;
          const seconds = Math.floor(delta);
          const secondsString = seconds > 0 ? `${seconds}s ` : "";
          status = `${prefix} ${hoursString} ${minutesString} ${secondsString}`;
          console.log("status", status);
          if (prefix === "overdue") {
            status = OtaState.Started;
          }
        }
      } else {
        status = execution?.subSessionStatus ?? "-";
      }

      const batchNo = execution?.subSessionId?.split("-").slice(-1)[0] ?? "-";

      return [
        execution?.subSessionId ?? "-", //execution?.otaBatchId ?? "-", // ID
        batchNo, // batchNo
        execution?.deviceType ?? "-", //deviceType
        execution?.firmware ?? "-", // firmware
        execution.createdAt ?? "0", //createdAt
        status, // status
        execution?.Idle ?? "?", // idle
        execution?.InProgress ?? "?", // inprogress
        execution?.Failed ?? "?", // failed
        execution?.Done ?? "?", // done
        execution.updatedAt ?? "0", //execution?.updatedAt ?? "?", // latestupdate
        { execution }, // start/stop
      ];
    });

    this.setState({ tableData });
  }

  private handleConfirmStopExecution = async (): Promise<void> => {
    if (!this.state.stopExecutionAction) {
      return;
    }

    this.setState({ loading: true, stopExecutionAction: undefined });
    try {
      await OtaManager.getInstance().stopOtaBatch(this.state.stopExecutionAction.execution.subSessionId ?? ""); // TODO: check this
    } catch (error) {
      console.error("stopOtaBatch", error);
      this.setState({
        errorMsg: translations.admin.texts.failedToDeleteSubscription(),
      });
    }
    this.setState({ loading: false });
  };

  private handleAddIconClick = async (): Promise<void> => {
    this.setState({ showAddBatchUpdatePopup: true });
  };

  private handleRefreshIconClick = async (): Promise<void> => {
    await this.performLoad(this.updateTableData(true));
  };

  private handleClosePopup = (): void => {
    this.setState({
      showAddBatchUpdatePopup: false,
      errorMsg: undefined,
    });
    this.handleRefreshIconClick();
  };

  private handleExecDetailsClose = (): void => {
    this.setState({
      execDetails: "",
      errorMsg: undefined,
    });
  };

  private handleCancelStopExecution = (): void => {
    this.setState({ stopExecutionAction: undefined });
  };

  private renderBatchUpdateExecutionsTable(): ReactNode {
    const titleElement = (
      <div>
        <NotificationsIcon className="notification-organization-icon" />
        <Typography variant="h6">{translations.admin.texts.otaManagement()}</Typography>
      </div>
    );

    return (
      <Fragment>
        <MUIDataTable
          title={titleElement}
          data={this.state.tableData}
          columns={this.tableColumns}
          options={this.tableOptions}
        />
        <Loader show={this.state.loading} />
      </Fragment>
    );
  }

  private renderButtonsRow(): ReactNode {
    if (this.state.loading) return;
    return (
      <div className="notification-buttons-container">
        <IconButton
          title={translations.common.buttons.refresh()}
          onClick={this.handleRefreshIconClick}
          style={{ backgroundColor: "#e6e6e6", marginLeft: "3rem", marginRight: "2rem", marginTop: "1rem" }}
          size="small"
        >
          <RefreshIcon />
        </IconButton>
        <IconButton
          title={translations.common.buttons.add()}
          onClick={this.handleAddIconClick}
          style={{ backgroundColor: "#e6e6e6", marginLeft: "3rem", marginRight: "2rem", marginTop: "1rem" }}
          size="small"
        >
          <AddIcon />
        </IconButton>
      </div>
    );
  }

  private renderStopExecutionPopup(): ReactNode {
    if (!this.state.stopExecutionAction) {
      return;
    }
    return (
      <ConfirmationDialog
        title={translations.admin.texts.stopExecution()}
        message={translations.admin.texts.stop() + " " + this.state.stopExecutionAction.execution.subSessionId}
        onConfirm={this.handleConfirmStopExecution}
        onCancel={this.handleCancelStopExecution}
      />
    );
  }

  private renderAddBatchUpdatePopup(): ReactNode {
    return (
      <AddBatchUpdatePopup
        organization={this.props.organization}
        users={[]}
        events={this.state.eventDefinitions}
        open={this.state.showAddBatchUpdatePopup}
        onClose={async (): Promise<void> => {
          this.handleClosePopup();
        }}
      />
    );
  }

  private renderSubsessionDetailsPopup(): ReactNode {
    if (this.state.execDetails !== "") {
      return (
        <SubSessionDetails
          open={true}
          onClose={async (): Promise<void> => {
            this.handleExecDetailsClose();
          }}
          subSessionId={this.state.execDetails}
        />
      );
    }
  }

  private handleCloseErrorNote = (): void => {
    this.setState({ errorMsg: undefined });
  };

  private renderErrorNote(): ReactNode {
    if (this.state.errorMsg) {
      return <ErrorDialog errorMsg={this.state.errorMsg} onClose={this.handleCloseErrorNote} />;
    }
  }

  public render(): JSX.Element {
    return (
      <Fragment>
        {this.renderErrorNote()}
        {this.renderButtonsRow()}
        {this.renderBatchUpdateExecutionsTable()}
        {this.renderButtonsRow()}
        {this.renderAddBatchUpdatePopup()}
        {this.renderStopExecutionPopup()}
        {this.renderSubsessionDetailsPopup()}
      </Fragment>
    );
  }
}
