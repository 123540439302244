/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import { AuthListener, AuthEvent, BackendFactory, AuthWrapper } from "@sade/data-access";

export default class ViewAccessMethods {
  private static supportAdminAccess: boolean | undefined;
  private static supportAccess: boolean | undefined;

  private static authCallback = (event: AuthEvent): void => {
    if (event === "SignedOut") {
      ViewAccessMethods.supportAdminAccess = undefined;
      ViewAccessMethods.supportAccess = undefined;
    }
  };

  private static authListener = new AuthListener(ViewAccessMethods.authCallback);

  private static async hasRequiredAccess(allowedPolicyGroups: string[]): Promise<boolean> {
    try {
      const organizationBackend = BackendFactory.getOrganizationBackend();
      const user = await organizationBackend.getCurrentUser();

      if (!user) {
        return false;
      }

      const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();
      console.debug("User policy groups", await user.getPolicyGroups());
      console.debug("User claims", claims);

      for (const policyGroup of allowedPolicyGroups) {
        if (await user.hasPolicyGroupNamed(policyGroup)) {
          return true;
        }
      }

      // The user has all pass grants, can access everything
      if (claims?.grants[claims.homeOrganizationId].includes("*")) {
        return true;
      }

      return false;
    } catch (err) {
      console.error("Failed to check for admin rights", err);
      // user did not have the access rights to retrieve user grants (hasPolicyGroupNamed)
      return false;
    }
  }

  public static async hasSupportAdminAccess(): Promise<boolean> {
    if (ViewAccessMethods.supportAdminAccess != null) {
      return ViewAccessMethods.supportAdminAccess;
    }

    const allowedPolicyGroups = ["SupportAdmin"];
    const hasAccess = await ViewAccessMethods.hasRequiredAccess(allowedPolicyGroups);
    ViewAccessMethods.supportAdminAccess = hasAccess;

    ViewAccessMethods.logPermission("SupportAdmin*", ViewAccessMethods.supportAdminAccess);
    return ViewAccessMethods.supportAdminAccess;
  }

  public static async hasSupportAccess(): Promise<boolean> {
    if (ViewAccessMethods.supportAccess != null) {
      return ViewAccessMethods.supportAccess;
    }

    const allowedPolicyGroups = ["SupportAdmin", "Support"];
    const hasAccess = await ViewAccessMethods.hasRequiredAccess(allowedPolicyGroups);
    ViewAccessMethods.supportAccess = hasAccess;

    ViewAccessMethods.logPermission("Support*", ViewAccessMethods.supportAccess);
    return ViewAccessMethods.supportAccess;
  }

  private static logPermission(name: string, access: boolean): void {
    console.log(`User ${access ? "has" : "does not have"} ${name} access`);
  }
}
