/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { Component, Fragment, FunctionComponent, PropsWithChildren, ReactElement, ReactNode } from "react";
import { Grid, Snackbar, Tab, Tabs } from "@material-ui/core";
import { AuthWrapper, BackendFactory, Organization, UserAdminDetails } from "@sade/data-access";
import InlineBox from "../ui/inline-box";
import TabPanel from "../ui/tab-panel";
import ViewAccessMethods from "../../ViewAccessMethods";
import accessControlled from "../access-control/access-controlled";
import NotificationManagement from "./components/notification-management";

import { translations } from "../../generated/translationHelper";
import { OrganizationSearch } from "./components/organization-search";
import Overview from "./components/overview";
import Devices from "./components/devices";
import UsernameRenderer from "./components/display-username";
import Loader from "../ui/loader";
import { LandingView } from "./components/landing-view";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Alert } from "@material-ui/lab";

enum PageTabs {
  "overview" = 0,
  "devices",
  "notifications",
}

type TabsKey = keyof typeof PageTabs;

const getEnumKeyByValue = (value: number): TabsKey => {
  const keys = [...Object.keys(PageTabs)] as TabsKey[];
  const key = keys.find((key: TabsKey) => PageTabs[key] === value);
  if (!key) return "overview";
  return key;
};

type Props = RouteComponentProps<{ organizationId?: string; selectedTab?: string }>;

interface State {
  selectedTab: number;
  selectedOrganization?: Organization;
  currentUserId?: string;
  searchFilter?: string;
  errorMsg?: string;
  userDetails?: UserAdminDetails;
  loading: boolean;
  errorState: boolean;
  hasAdminAccess?: boolean;
}

type TabPageProps = { currentPage: number; index: number };

const TabPage: FunctionComponent<TabPageProps> = (props: PropsWithChildren<TabPageProps>): ReactElement => {
  return (
    <TabPanel value={props.currentPage} index={props.index}>
      <InlineBox>{props.children}</InlineBox>
    </TabPanel>
  );
};

class SupportView extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      selectedTab: PageTabs.overview,
      loading: false,
      errorState: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    const claims = await AuthWrapper.getCurrentAuthenticatedUserClaims();
    if (!claims) {
      throw new Error("Failed to resolve current user!");
    }
    this.setState({
      currentUserId: claims.userId,
      hasAdminAccess: await ViewAccessMethods.hasSupportAdminAccess(),
    });
    if (this.props.match.params.organizationId) {
      this.setSelectedOrganizationFromURL();
    }
  }

  private async setSelectedOrganizationFromURL(): Promise<void> {
    const organizationId = "ORG/" + this.props.match.params.organizationId;
    const selectedTab = this.props.match.params.selectedTab;
    const tab = selectedTab ? PageTabs[selectedTab as TabsKey] : PageTabs.overview;
    this.setState({
      loading: true,
      selectedTab: tab ? tab : PageTabs.overview,
    });
    try {
      const organization = await BackendFactory.getOrganizationBackend().getOrganization(organizationId);
      if (organization) {
        this.updateUserDetails(organization).then();
        this.setState({
          selectedOrganization: organization,
        });
      }
    } catch (error) {
      console.log(error);
      this.setState({
        loading: false,
        errorMsg: `No organization found for id: ${organizationId.slice(4)}`,
        errorState: true,
      });
    }
  }

  private updateURL = (): void => {
    const { selectedOrganization } = this.state;
    if (!selectedOrganization) return;
    const path = this.props.location.pathname;
    const urlId = selectedOrganization.getId().slice(4);
    const basePath = this.props.match.params.organizationId
      ? path.substring(0, path.lastIndexOf(`/${this.props.match.params.organizationId}`))
      : path;
    this.props.history.push(`${basePath}/${urlId}/${getEnumKeyByValue(this.state.selectedTab)}`);
  };

  private handleOrganizationSelected = (organization: Organization): void => {
    this.updateUserDetails(organization).then();
    this.setState({
      selectedOrganization: organization,
    });
    this.updateURL();
  };

  private updateUserDetails = async (organization: Organization): Promise<void> => {
    this.setState({
      loading: true,
    });
    const [user] = await organization.getUsers();
    const details = await BackendFactory.getOrganizationBackend().getUserAdmin(user.getId());
    this.setState({
      userDetails: details,
      loading: false,
    });
  };

  private handlePageChange = (event: React.ChangeEvent<unknown>, value: number): void => {
    console.log(`Changing page from ${this.state.selectedTab} to ${value}`);
    this.setState({ selectedTab: PageTabs[getEnumKeyByValue(value)] }, () => {
      this.updateURL();
    });
  };

  private renderOverview(): ReactNode {
    if (this.state.loading) return <Loader />;
    if (!this.state.userDetails) return null;
    return <Overview userDetails={this.state.userDetails} />;
  }

  private renderDevices(): ReactNode {
    if (this.state.selectedOrganization && this.state.userDetails) {
      return <Devices organization={this.state.selectedOrganization} userDetails={this.state.userDetails} />;
    }
  }

  private renderNotificationManager(): ReactNode {
    if (this.state.selectedOrganization && this.state.currentUserId) {
      return (
        <NotificationManagement
          organization={this.state.selectedOrganization}
          currentUserId={this.state.currentUserId}
        />
      );
    }
  }

  private handleSnackbarClose = (event: React.SyntheticEvent, reason?: string): void => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({
      errorState: false,
    });
  };

  public render(): ReactNode {
    if (this.state.loading) {
      return <Loader />;
    }
    if (!this.state.selectedOrganization || this.state.errorState)
      return (
        <Fragment>
          <LandingView
            onOptionSelect={(selectedOrganization): void => void this.handleOrganizationSelected(selectedOrganization)}
          />
          <Snackbar open={this.state.errorState} autoHideDuration={6000} onClose={this.handleSnackbarClose}>
            <Alert severity="error" onClose={this.handleSnackbarClose}>
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
        </Fragment>
      );
    return (
      <div style={{ margin: "1rem" }}>
        <Grid container={true} spacing={1} justifyContent={"center"}>
          <Grid item={true} md={12} lg={10} xl={8}>
            <Grid container justifyContent="space-between">
              <Grid item>
                {this.state.userDetails ? <UsernameRenderer userDetails={this.state.userDetails} /> : null}
              </Grid>
              <Grid item xs={4}>
                <OrganizationSearch
                  onOptionSelect={(selectedOrganization): void =>
                    void this.handleOrganizationSelected(selectedOrganization)
                  }
                />
              </Grid>
            </Grid>
            <Tabs value={this.state.selectedTab} onChange={this.handlePageChange}>
              <Tab label={translations.admin.texts.overview()} />
              <Tab label={translations.admin.texts.devices()} data-testid="devices-tab" />
              <Tab label={translations.admin.texts.notifications()} />
            </Tabs>
            <TabPage currentPage={this.state.selectedTab} index={0}>
              {this.renderOverview()}
            </TabPage>
            <TabPage currentPage={this.state.selectedTab} index={1}>
              {this.renderDevices()}
            </TabPage>
            <TabPage currentPage={this.state.selectedTab} index={2}>
              {this.renderNotificationManager()}
            </TabPage>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(accessControlled(SupportView, ViewAccessMethods.hasSupportAccess));
