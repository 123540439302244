/*
 * Copyright (C) 2024 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, Fragment, ReactNode } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { OtaManager } from "@sade/data-access";
import { DeviceUpdateState } from "@sade/data-access/lib/generated/gqlDevice";
import { translations } from "../../../../generated/translationHelper";
import Loader from "../../../ui/loader";

interface Props {
  open: boolean;
  onClose: () => void;
  subSessionId: string;
}

interface State {
  subSessionId: string;
  searchQuery: string;
  devices: DeviceUpdateState[];
  loading: boolean;
}

export default class SubSessionDetails extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      subSessionId: props.subSessionId,
      searchQuery: "",
      devices: [],
      loading: true,
    };
  }

  public async componentDidMount(): Promise<void> {
    const details = await OtaManager.getInstance().getOtaBatchSubSessionDetails(this.state.subSessionId);
    this.setState({
      devices: details.devices,
      searchQuery: details.queryString,
      loading: false,
    });
  }

  private closeThis(): void {
    this.setState({
      searchQuery: "",
    });
    this.props.onClose();
  }

  public render(): ReactNode {
    const devices = this.state.devices.map((device: DeviceUpdateState) => {
      return (
        <Grid container spacing={0} key={device.deviceId}>
          <Grid item xs={7}>
            <FormLabel>{device.deviceId}</FormLabel>
          </Grid>
          <Grid item xs={5}>
            <FormLabel>{device.updateState}</FormLabel>
          </Grid>
        </Grid>
      );
    });

    const content = this.state.loading ? (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormLabel>{translations.admin.texts.subsessiondId()}</FormLabel>
        </Grid>
        <Grid item xs={9}>
          <FormLabel>{this.state.subSessionId}</FormLabel>
        </Grid>
        <Grid item xs={12}>
          <Loader />
        </Grid>
      </Grid>
    ) : (
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormLabel>{translations.admin.texts.subsessiondId()}</FormLabel>
        </Grid>
        <Grid item xs={9}>
          <FormLabel>{this.state.subSessionId}</FormLabel>
        </Grid>
        <Grid item xs={3}>
          <FormLabel>{translations.admin.texts.query()}</FormLabel>
        </Grid>
        <Grid item xs={9}>
          <FormLabel>{this.state.searchQuery}</FormLabel>
        </Grid>
        <Grid item xs={3}>
          <FormLabel>{translations.admin.texts.devices()}</FormLabel>
        </Grid>
        <Grid item xs={9}>
          {devices}
        </Grid>
      </Grid>
    );

    return (
      <Fragment>
        <Dialog open={this.props.open}>
          <DialogTitle>
            {translations.admin.texts.subsessiondetails()}
            <IconButton aria-label="close" onClick={(): void => void this.closeThis()}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>{content}</DialogContent>
          <DialogActions>
            <Button onClick={(): void => void this.closeThis()}>{translations.common.buttons.close()}</Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}
