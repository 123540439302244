/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { BaseObservable } from "../observer";
export var DeviceControlLevel;
(function (DeviceControlLevel) {
    DeviceControlLevel["Owner"] = "Owner";
    DeviceControlLevel["CoOwner"] = "CoOwner";
    DeviceControlLevel["Guest"] = "Guest";
    DeviceControlLevel["NoAccess"] = "NoAccess";
})(DeviceControlLevel || (DeviceControlLevel = {}));
export var DevicePowerLevel;
(function (DevicePowerLevel) {
    DevicePowerLevel["Low"] = "LOW";
    DevicePowerLevel["Medium"] = "MED";
    DevicePowerLevel["High"] = "HIGH";
})(DevicePowerLevel || (DevicePowerLevel = {}));
export var CloudLoggingState;
(function (CloudLoggingState) {
    CloudLoggingState["Continuous"] = "CONTINUOUS";
    CloudLoggingState["Disabled"] = "DISABLED";
    CloudLoggingState["Manual"] = "MANUAL";
})(CloudLoggingState || (CloudLoggingState = {}));
export var RadioConnectionStatus;
(function (RadioConnectionStatus) {
    RadioConnectionStatus[RadioConnectionStatus["Unknown"] = -1] = "Unknown";
    RadioConnectionStatus[RadioConnectionStatus["Disconnected"] = 0] = "Disconnected";
    RadioConnectionStatus[RadioConnectionStatus["Connected"] = 1] = "Connected";
})(RadioConnectionStatus || (RadioConnectionStatus = {}));
export class Device extends BaseObservable {
    static instanceOf(value) {
        return value instanceof Device;
    }
}
