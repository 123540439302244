/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter } from "tslib";
import { AppSyncClientFactory } from "../backend/AppSyncClientFactory";
import { Service } from "../backend/AppSyncClientProvider";
import { timestampToMilliseconds } from "../utils";
import { DevicesStatesUpdateDocument } from "../../generated/gqlDevice";
export class DeviceState {
    constructor(id, reported, desired, timestamp, version, connectionState) {
        this.reported = reported;
        this.desired = desired;
        // Changed shadow properties need to be added to this object when setting property value
        this.changedValues = {};
        this.id = id;
        this.updateTimestamp = timestamp;
        this.shadowVersion = version;
        this.connState = connectionState;
    }
    get deviceId() {
        return this.id;
    }
    get displayName() {
        return (this.deltaExists("displayName") ? this.desired.displayName : this.reported.displayName);
    }
    set displayName(name) {
        this.desired.displayName = name;
        this.changedValues.displayName = name;
    }
    get otaId() {
        return this.reported.otaId;
    }
    set otaId(id) {
        this.desired.otaId = id;
        this.changedValues.otaId = id;
    }
    get version() {
        var _a;
        return (_a = this.shadowVersion) !== null && _a !== void 0 ? _a : undefined;
    }
    // Returns true if one of the properties is being taken into use in the device side
    beingApplied(key) {
        return this.deltaExists(key) && this.changedValues[key] === undefined;
    }
    deltaExists(key) {
        return this.desired[key] != null && this.desired[key] !== this.reported[key];
    }
    get connectionState() {
        var _a;
        return (_a = this.connState) !== null && _a !== void 0 ? _a : undefined;
    }
    getStateUpdatedTimestampMillis() {
        if (this.updateTimestamp) {
            return timestampToMilliseconds(this.updateTimestamp);
        }
    }
    requestChanges() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("Store state, payload " + JSON.stringify(this.changedValues));
            try {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                yield client.mutate(DevicesStatesUpdateDocument, {
                    deviceId: this.deviceId,
                    state: JSON.stringify(this.changedValues),
                });
                this.changedValues = {};
            }
            catch (error) {
                console.error("Error", error);
            }
        });
    }
    store(fetchState = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.applyChangesTimeout) {
                clearTimeout(this.applyChangesTimeout);
            }
            fetchState
                ? yield this.requestChanges()
                : (this.applyChangesTimeout = setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                    this.requestChanges().then(() => {
                        console.log("State changes requested, start WD");
                    });
                }), 1300));
        });
    }
    revert() {
        const keys = Object.keys(this.changedValues);
        keys.forEach((key) => {
            this.desired[key] = this.reported[key];
        });
        this.changedValues = {};
    }
    get firmwareVersion() {
        return this.reported.deviceFwVersion;
    }
    static instanceOf(state) {
        return state instanceof DeviceState;
    }
    get stage() {
        return this.reported.stage;
    }
    set stage(stage) {
        this.desired.stage = stage;
        this.changedValues.stage = stage;
    }
    get remoteLogging() {
        var _a;
        return (_a = this.reported.remoteLogging) !== null && _a !== void 0 ? _a : this.desired.remoteLogging;
    }
    set remoteLogging(remoteLogging) {
        this.desired.remoteLogging = remoteLogging;
        this.changedValues.remoteLogging = remoteLogging;
    }
}
