/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
import { __awaiter, __rest } from "tslib";
import { AppSyncClientFactory, Service } from "../backend";
import { DeviceGroupsOtaUpdatesCancelDocument, DeviceGroupsOtaUpdatesStartDocument, OtaUpdatesBatchListDocument, OtaBatchCreateSessionDocument, OtaUpdatesBatchStopDocument, DevicesOtaUpdatesCancelDocument, DevicesOtaUpdatesStartDocument, OtaBatchGetAllSubSessionsDocument, OtaUpdatesListDocument, OtaUpdatesStatesListDocument, OtaUpdatesStatesUpdateFeedDocument, OtaUpdatesBatchFeedDocument, OtaBatchStartDocument, OtaBatchStopDocument, OtaBatchGetSubSessionDetailsDocument, } from "../../generated/gqlDevice";
import { ReceiverManager } from "../utils";
import { BaseObservable } from "../observer";
import { AppConfiguration } from "../..";
export class OtaManager extends BaseObservable {
    constructor() {
        super(...arguments);
        this.subscriptions = [];
    }
    static getInstance() {
        if (!OtaManager.instance) {
            OtaManager.instance = new OtaManager();
        }
        return this.instance;
    }
    static parseStateFragment(fragment) {
        const { timestamp } = fragment, rest = __rest(fragment, ["timestamp"]);
        return Object.assign({ timestamp: typeof timestamp === "string" ? Number.parseInt(timestamp) : timestamp }, rest);
    }
    init(organizationId) {
        return __awaiter(this, void 0, void 0, function* () {
            ReceiverManager.instance.addObserver(this);
            this.organizationId = organizationId;
            if (organizationId) {
                ReceiverManager.instance.addReceivers(organizationId);
            }
        });
    }
    uninit() {
        if (this.organizationId) {
            ReceiverManager.instance.removeReceivers(this.organizationId);
        }
        ReceiverManager.instance.removeObserver(this);
        this.unsubscribe();
    }
    getOtaUpdates(deviceType, forceRefresh = false, includeNonPublished = false) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.otaUpdates || forceRefresh) {
                return yield this.fetchOtaUpdates(deviceType, includeNonPublished);
            }
            else {
                return this.otaUpdates;
            }
        });
    }
    getOtaUpdatesByType(deviceType, forceRefresh = false, includeNonPublished = false) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.otaUpdatesByType ||
                forceRefresh ||
                this.otaUpdatesByType.filter((update) => update.type === deviceType).length === 0) {
                return yield this.fetchOtaUpdatesByType(deviceType, includeNonPublished);
            }
            else {
                return (_b = (_a = this.otaUpdatesByType.filter((update) => update.type === deviceType)[0]) === null || _a === void 0 ? void 0 : _a.otaUpdates) !== null && _b !== void 0 ? _b : [];
            }
        });
    }
    getOtaUpdateStates(forceRefresh) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.otaUpdateStates || forceRefresh) {
                return yield this.fetchOtaUpdateStates();
            }
            else {
                return this.otaUpdateStates;
            }
        });
    }
    getOtaBatchExecutions(forceRefresh) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.otaExecutions || forceRefresh) {
                return yield this.fetchOtaBatchExecutions();
            }
            else {
                return this.otaExecutions;
            }
        });
    }
    getOtaBatchSubSessions(forceRefresh) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.subSessions || forceRefresh) {
                return yield this.fetchOtaBatchSubSessions();
            }
            else {
                return this.subSessions;
            }
        });
    }
    getOtaBatchSubSessionDetails(subSessionId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.fetchOtaBatchSubSessionDetails(subSessionId);
        });
    }
    stopOtaBatchExecution(id) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            console.log(`stopOtaBatchExecution ${id}`);
            try {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                const response = yield client.mutate(OtaUpdatesBatchStopDocument, { id });
                if (response.errors) {
                    console.error("Failed to stop batch execution", response.errors);
                    return false;
                }
                return (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.otaUpdatesBatchStop) !== null && _b !== void 0 ? _b : false;
            }
            catch (error) {
                console.error("Error", error);
                return false;
            }
        });
    }
    createOtaBatchSession(devicesInSessions, notificationPeriod, otaId, fwVersion, deviceType, query) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            console.log(`createOtaBatchSession ${devicesInSessions}, ${notificationPeriod}, ${otaId}, ${query}`);
            try {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                const response = yield client.mutate(OtaBatchCreateSessionDocument, {
                    devicesInSessions,
                    notificationPeriod,
                    otaId,
                    query,
                    fwVersion,
                    deviceType,
                });
                if (response.errors) {
                    console.error("Failed to create batch session", response.errors);
                    throw new Error(JSON.stringify(response.errors));
                }
                return (_a = response.data) === null || _a === void 0 ? void 0 : _a.otaBatchCreateSession;
            }
            catch (error) {
                console.error("Error", error);
                throw new Error(error.message);
            }
        });
    }
    startOtaBatch(subSessionId) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            console.log(`startOtaBatch ${subSessionId}`);
            try {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                const response = yield client.mutate(OtaBatchStartDocument, { otaBatchId: subSessionId });
                if (response.errors) {
                    console.error("Failed to start batch execution", response.errors);
                    throw new Error(JSON.stringify(response.errors));
                }
                console.log(JSON.stringify(response));
                return (_a = response.data) === null || _a === void 0 ? void 0 : _a.otaBatchStart;
            }
            catch (error) {
                console.error("Error", error);
                throw new Error(error.message);
            }
        });
    }
    stopOtaBatch(subSessionId) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            console.log(`stopOtaBatch ${subSessionId}`);
            try {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                const response = yield client.mutate(OtaBatchStopDocument, { subSessionId });
                if (response.errors) {
                    console.error("Failed to start batch execution", response.errors);
                    throw new Error(JSON.stringify(response.errors));
                }
                console.log(JSON.stringify(response));
                return (_a = response.data) === null || _a === void 0 ? void 0 : _a.otaBatchStop;
            }
            catch (error) {
                console.error("Error", error);
                throw new Error(error.message);
            }
        });
    }
    triggerDeviceOtaUpdate(deviceId, otaId) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(`triggerDeviceOtaUpdate ${deviceId}, ${otaId}`);
            try {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                const response = yield client.mutate(DevicesOtaUpdatesStartDocument, { deviceId, otaId });
                if (response.errors) {
                    console.error("Failed to start ota update", response.errors);
                }
            }
            catch (error) {
                console.error("Error", error);
            }
        });
    }
    triggerGroupOtaUpdate(groupId, otaId) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(`triggerGroupOtaUpdate ${groupId}, ${otaId}`);
            try {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                const response = yield client.mutate(DeviceGroupsOtaUpdatesStartDocument, { groupId, otaId });
                if (response.errors) {
                    console.error("Failed to start group ota update", response.errors);
                }
            }
            catch (error) {
                console.error("Error", error);
            }
        });
    }
    cancelDeviceOtaUpdate(deviceId) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(`cancelDeviceOtaUpdate ${deviceId}`);
            try {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                const response = yield client.mutate(DevicesOtaUpdatesCancelDocument, { deviceId });
                if (response.errors) {
                    console.error("Failed to cancel ota update", response.errors);
                }
            }
            catch (error) {
                console.error("Error", error);
            }
        });
    }
    cancelGroupOtaUpdate(groupId) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(`cancelGroupOtaUpdate ${groupId}`);
            try {
                const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
                const response = yield client.mutate(DeviceGroupsOtaUpdatesCancelDocument, { groupId });
                if (response.errors) {
                    console.error("Failed to cancel group ota update", response.errors);
                }
            }
            catch (error) {
                console.error("Error", error);
            }
        });
    }
    onReceiversChanged(receivers) {
        this.unsubscribe();
        this.subscriptions.push(...receivers.map((receiver) => this.subscribeWithIdentity(receiver)));
        this.subscriptions.push(...receivers.map((receiver) => this.subscribeBatchUpdatesWithIdentity(receiver)));
    }
    fetchOtaUpdates(deviceType, includeNonPublished = false) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            let nextToken = null;
            let otaUpdates = [];
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            try {
                do {
                    const response = yield client.query(OtaUpdatesListDocument, {
                        nextToken,
                        deviceType,
                        includeNonPublished,
                    });
                    // cast is required or response is stuck in cyclic type inference loop
                    nextToken = ((_b = (_a = response.data.otaUpdatesList) === null || _a === void 0 ? void 0 : _a.nextToken) !== null && _b !== void 0 ? _b : null);
                    otaUpdates = otaUpdates.concat((_d = (_c = response.data.otaUpdatesList) === null || _c === void 0 ? void 0 : _c.otaUpdates) !== null && _d !== void 0 ? _d : []);
                    if (response.errors) {
                        console.error("Failed to query ota updates list", response.errors);
                    }
                } while (nextToken);
                this.otaUpdates = otaUpdates;
                return otaUpdates;
            }
            catch (error) {
                console.error("Error", error);
                return [];
            }
        });
    }
    fetchOtaUpdatesByType(deviceType, includeNonPublished = false) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            let nextToken = null;
            let otaUpdates = [];
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            try {
                do {
                    const response = yield client.query(OtaUpdatesListDocument, {
                        nextToken,
                        deviceType,
                        includeNonPublished,
                    });
                    // cast is required or response is stuck in cyclic type inference loop
                    nextToken = ((_b = (_a = response.data.otaUpdatesList) === null || _a === void 0 ? void 0 : _a.nextToken) !== null && _b !== void 0 ? _b : null);
                    otaUpdates = otaUpdates.concat((_d = (_c = response.data.otaUpdatesList) === null || _c === void 0 ? void 0 : _c.otaUpdates) !== null && _d !== void 0 ? _d : []);
                    if (response.errors) {
                        console.error("Failed to query ota updates list", response.errors);
                    }
                } while (nextToken);
                if (this.otaUpdatesByType) {
                    this.otaUpdatesByType = this.otaUpdatesByType.filter((update) => update.type !== deviceType);
                    this.otaUpdatesByType.push({ type: deviceType, otaUpdates });
                }
                else {
                    this.otaUpdatesByType = [{ type: deviceType, otaUpdates }];
                }
                return otaUpdates;
            }
            catch (error) {
                console.error("Error", error);
                return [];
            }
        });
    }
    fetchLatestGdoUpdate(deviceType) {
        return __awaiter(this, void 0, void 0, function* () {
            const region = AppConfiguration.getAwsConfiguration().AppSync.Region;
            if (!region) {
                console.error("Failed to get region");
                return;
            }
            const deviceServiceUrl = AppConfiguration.getResources().restApis.device.url;
            if (!deviceServiceUrl) {
                console.error("Failed to get device service url");
                return;
            }
            try {
                const headers = {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                    },
                };
                const response = yield fetch(deviceServiceUrl + "/devices/gdo/latest?type=" + deviceType, headers);
                if (response.status !== 200) {
                    console.error("Failed to fetch latest GDO update", response);
                    return;
                }
                return (yield response.json());
            }
            catch (error) {
                console.error("Error", error);
                return;
            }
        });
    }
    parseBatchExecutions(item) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const otaExecution = {
            organizationId: (_a = item.organizationId) !== null && _a !== void 0 ? _a : undefined,
            id: item.id,
            currentCount: (_b = item.currentCount) !== null && _b !== void 0 ? _b : undefined,
            stopDate: (_c = item.stopDate) !== null && _c !== void 0 ? _c : undefined,
            startDate: (_d = item.startDate) !== null && _d !== void 0 ? _d : undefined,
            maxCount: (_e = item.maxCount) !== null && _e !== void 0 ? _e : undefined,
            dailyMaxCount: (_f = item.dailyMaxCount) !== null && _f !== void 0 ? _f : undefined,
            searchQuery: (_g = item.searchQuery) !== null && _g !== void 0 ? _g : undefined,
            userId: (_h = item.userId) !== null && _h !== void 0 ? _h : undefined,
        };
        return otaExecution;
    }
    parseSubSessions(item) {
        const otaSubSession = {
            otaBatchId: item.otaBatchId,
            subSessionId: item.subSessionId,
            updatedAt: item.updatedAt,
            subSessionStatus: item.subSessionStatus,
            waitUntil: item.waitUntil,
            Idle: item.Idle,
            InProgress: item.InProgress,
            Done: item.Done,
            Failed: item.Failed,
            Cancelled: item.Cancelled,
            deviceType: item.deviceType,
            firmware: item.firmware,
            createdAt: item.createdAt,
            activeSubSessionIndex: item.activeSubSessionIndex,
            runnable: item.runnable,
        };
        return otaSubSession;
    }
    fetchOtaUpdateStates() {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            let nextToken = null;
            let otaUpdateStates = [];
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            try {
                do {
                    const response = yield client.query(OtaUpdatesStatesListDocument, { nextToken });
                    if (response.errors) {
                        console.error("Failed to query ota update states list", response.errors);
                    }
                    // cast is required or response is stuck in cyclic type inference loop
                    nextToken = ((_b = (_a = response.data.otaUpdatesStatesList) === null || _a === void 0 ? void 0 : _a.nextToken) !== null && _b !== void 0 ? _b : null);
                    const convertedStates = (_c = response.data.otaUpdatesStatesList) === null || _c === void 0 ? void 0 : _c.otaUpdateStates.map((fragment) => OtaManager.parseStateFragment(fragment));
                    otaUpdateStates = otaUpdateStates.concat(convertedStates !== null && convertedStates !== void 0 ? convertedStates : []);
                } while (nextToken);
                this.otaUpdateStates = otaUpdateStates;
                console.log(`fetchOtaUpdateStates ${JSON.stringify(this.otaUpdateStates)}`);
                return otaUpdateStates;
            }
            catch (error) {
                console.error("Error", error);
                return [];
            }
        });
    }
    fetchOtaBatchExecutions() {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            let nextToken = null;
            let otaExecutions = [];
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            try {
                do {
                    const response = yield client.query(OtaUpdatesBatchListDocument, { nextToken }, {
                        fetchPolicy: "network-only",
                    });
                    if (response.errors) {
                        console.error("Failed to query batch executions list", response.errors);
                    }
                    // cast is required or response is stuck in cyclic type inference loop
                    nextToken = ((_b = (_a = response.data.otaUpdatesBatchList) === null || _a === void 0 ? void 0 : _a.nextToken) !== null && _b !== void 0 ? _b : null);
                    otaExecutions = otaExecutions.concat((_d = (_c = response.data.otaUpdatesBatchList) === null || _c === void 0 ? void 0 : _c.otaBatchExecutions.map((execution) => this.parseBatchExecutions(execution))) !== null && _d !== void 0 ? _d : []);
                } while (nextToken);
            }
            catch (error) {
                console.error("Error", error);
            }
            this.otaExecutions = otaExecutions;
            return otaExecutions;
        });
    }
    fetchOtaBatchSubSessions() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            let subSessions = [];
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            try {
                const response = yield client.query(OtaBatchGetAllSubSessionsDocument, {}, {
                    fetchPolicy: "network-only",
                });
                if (response.errors) {
                    console.error("Failed to query batch executions list", response.errors);
                }
                // cast is required or response is stuck in cyclic type inference loop
                subSessions = subSessions.concat((_b = (_a = response.data.otaBatchGetAllSubSessions) === null || _a === void 0 ? void 0 : _a.subSessionStatus.map((execution) => this.parseSubSessions(execution))) !== null && _b !== void 0 ? _b : []);
            }
            catch (error) {
                console.error("Error", error);
            }
            this.subSessions = subSessions;
            return subSessions;
        });
    }
    fetchOtaBatchSubSessionDetails(subSessionId) {
        return __awaiter(this, void 0, void 0, function* () {
            const client = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
            try {
                const response = yield client.query(OtaBatchGetSubSessionDetailsDocument, { subSessionId }, {
                    fetchPolicy: "network-only",
                });
                if (response.errors) {
                    console.error("Failed to query batch executions list", response.errors);
                }
                const details = response.data.otaBatchGetSubSessionDetails;
                return details;
            }
            catch (error) {
                console.error("Error", error);
                throw new Error(error.message);
            }
        });
    }
    unsubscribe() {
        console.log("OtaManager unsubscribe");
        this.subscriptions.forEach((s) => {
            s.unsubscribe();
        });
        this.subscriptions = [];
    }
    parseBatchUpdateState(item) {
        var _a, _b;
        const otaState = {
            organizationId: item.organizationId,
            id: item.id,
            currentCount: (_a = item.currentCount) !== null && _a !== void 0 ? _a : undefined,
            stopDate: (_b = item.stopDate) !== null && _b !== void 0 ? _b : undefined,
        };
        return otaState;
    }
    subscribeWithIdentity(identity) {
        console.log("OtaManager subscribeWithIdentity: ", identity);
        const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        return appSyncClient.subscribe(OtaUpdatesStatesUpdateFeedDocument, { receiver: identity }).subscribe({
            // TODO: Fix any type
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error: (error) => {
                if (error.errorMessage === "AMQJS0008I Socket closed.") {
                    console.log("Reconnecting socket");
                    this.subscribeWithIdentity(identity);
                }
                console.error(error);
            },
            next: (update) => {
                var _a, _b;
                if ((_b = (_a = update.data) === null || _a === void 0 ? void 0 : _a.otaUpdatesStatesUpdateFeed) === null || _b === void 0 ? void 0 : _b.item) {
                    const state = OtaManager.parseStateFragment(update.data.otaUpdatesStatesUpdateFeed.item);
                    console.log("OTA state: " + JSON.stringify(state));
                    this.notifyAction((observer) => { var _a; return (_a = observer.onOtaUpdateState) === null || _a === void 0 ? void 0 : _a.call(observer, state); });
                }
            },
        });
    }
    subscribeBatchUpdatesWithIdentity(identity) {
        console.log("OtaManager subscribeBatchUpdatesWithIdentity: ", identity);
        const appSyncClient = AppSyncClientFactory.createProvider().getTypedClient(Service.DEVICE);
        return appSyncClient.subscribe(OtaUpdatesBatchFeedDocument, { receiver: identity }).subscribe({
            // TODO: Fix any type
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            error: (error) => {
                if (error.errorMessage === "AMQJS0008I Socket closed.") {
                    console.log("Reconnecting socket");
                    this.subscribeWithIdentity(identity);
                }
                console.error(error);
            },
            next: (update) => {
                var _a, _b;
                if ((_b = (_a = update.data) === null || _a === void 0 ? void 0 : _a.otaUpdatesBatchFeed) === null || _b === void 0 ? void 0 : _b.item) {
                    const state = update.data.otaUpdatesBatchFeed.item;
                    console.log("OTA batch update state: " + JSON.stringify(state));
                    this.notifyAction((observer) => { var _a; return (_a = observer.onOtaBatchUpdateState) === null || _a === void 0 ? void 0 : _a.call(observer, this.parseBatchUpdateState(state)); });
                }
            },
        });
    }
}
