/*
 * Copyright (C) 2023 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 *
 */

import React, { Component, Fragment, ReactNode } from "react";
import {
  Attribute,
  BarrierStatus,
  CloudLoggingState,
  ConnectionState,
  ConnectivityUnitHW,
  ConnectivityUnitHWData,
  ConnectivityUnitHWState,
  ConnectivityUnitHWStateProperties,
  Device,
} from "@sade/data-access";
import { Maybe, Nullable } from "@sade/data-access/lib/common/Utils";
import { Chip, Grid, List, ListItem } from "@material-ui/core";
import PageChanger from "../../../../ui/page-changer";
import { translations } from "../../../../../generated/translationHelper";
import OtaUpdateButton from "./ota-update-button";
import Loader from "../../../../ui/loader";
import { getStage } from "../../../../../utils/GetStage";
import DangerousDropdown from "./dangerous-dropdown";
import DropdownSelection from "../../../../ui/dropdown-selection";

interface Props {
  device: Device;
  deviceList: Device[];
  onDeviceSelect: (index: number) => void;
}

interface State {
  attributes: Attribute[];
  settingStage: boolean;
  settingRemoteLogging: boolean;
}

function isConnectivityUnit(
  device: Device
): device is ConnectivityUnitHW<ConnectivityUnitHWData, ConnectivityUnitHWState<ConnectivityUnitHWStateProperties>> {
  return ConnectivityUnitHW.instanceOf(device);
}

export default class DeviceDetails extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      attributes: this.props.device.getAttributes(),
      settingStage: false,
      settingRemoteLogging: false,
    };
  }

  private getState = (): Maybe<ConnectivityUnitHWState<ConnectivityUnitHWStateProperties>> => {
    if (isConnectivityUnit(this.props.device)) {
      return this.props.device.getState();
    }
  };

  private renderConnectionStatus(connected?: ConnectionState): ReactNode {
    if (connected?.connected) {
      return <Chip className="overview-chip-green" label={translations.admin.texts.connected()} />;
    } else {
      return <Chip className="overview-chip-red" label={translations.admin.texts.disconnected()} />;
    }
  }

  private renderDoorStatus(status?: Nullable<BarrierStatus>): ReactNode {
    if (!status) return;
    if (status.open === 1) {
      return <Chip className="overview-chip-green" label={translations.admin.texts.doorOpen()} />;
    } else if (status.open === 0) {
      return <Chip className="overview-chip-green" label={translations.admin.texts.doorClosed()} />;
    } else {
      return <Chip className="overview-chip-red" label={translations.admin.texts.unkown()} />;
    }
  }

  private renderStageDropdown(): ReactNode {
    const stageOptions = [
      { key: "prod", label: "prod" },
      { key: "alpha", label: "alpha" },
    ];

    const currentStage = getStage();
    const deviceState = this.props.device.getState();
    const deviceStageIndex = stageOptions.findIndex((option) => option.label === deviceState?.stage);
    const currentStageIndex = stageOptions.findIndex((option) => option.label === currentStage);
    let currentSelection = 0;
    if (deviceStageIndex !== -1) {
      currentSelection = deviceStageIndex;
    } else if (currentStageIndex !== -1) {
      currentSelection = currentStageIndex;
    } else {
      currentSelection = 0;
    }

    return (
      <Grid container justifyContent="flex-start" spacing={1}>
        <Grid item>
          <DangerousDropdown
            confirmationTitle={translations.deviceSettings.texts.stageChangeConfirmationTitle()}
            confirmationMessage={translations.deviceSettings.texts.stageChangeConfirmationMessage()}
            currentSelection={currentSelection}
            onSelect={async (selection): Promise<void> => {
              if (deviceState) {
                this.setState({ settingStage: true });
                deviceState.stage = stageOptions[selection ?? 0].label;
                await deviceState.store(true);
                this.setState({ settingStage: false });
              }
            }}
            selectionList={stageOptions}
            disabled={this.state.settingStage || !deviceState}
          />
        </Grid>
        <Grid item alignContent="center">
          <Loader size={"small"} topBottomPadding="0" leftRightPadding="1rem" show={this.state.settingStage} />
        </Grid>
      </Grid>
    );
  }

  private renderRemoteLoggingDropdown(): ReactNode {
    const remoteLoggingOptions = [
      { key: CloudLoggingState.Disabled, label: translations.deviceSettings.remoteLogging.disabled() },
      { key: CloudLoggingState.Manual, label: translations.deviceSettings.remoteLogging.manual() },
      { key: CloudLoggingState.Continuous, label: translations.deviceSettings.remoteLogging.continuous() },
    ];

    const deviceState = this.props.device.getState();
    const deviceRemoteLoggingIndex = remoteLoggingOptions.findIndex(
      (option) => option.key === deviceState?.remoteLogging
    );
    const currentSelection = deviceRemoteLoggingIndex !== -1 ? deviceRemoteLoggingIndex : 0;

    return (
      <Grid container justifyContent="flex-start" spacing={1}>
        <Grid item>
          <DropdownSelection
            currentSelection={currentSelection}
            onSelect={async (selection): Promise<void> => {
              if (deviceState) {
                this.setState({ settingRemoteLogging: true });
                const remoteLoggingSelection = remoteLoggingOptions[selection ?? 0].key;
                deviceState.remoteLogging = remoteLoggingSelection;
                await deviceState.store(true);
                await this.props.device.setRemoteLogging(remoteLoggingSelection);
                this.setState({ settingRemoteLogging: false });
              }
            }}
            selectionList={remoteLoggingOptions}
            disabled={this.state.settingRemoteLogging || !deviceState}
          />
        </Grid>
        <Grid item alignContent="center">
          <Loader size={"small"} topBottomPadding="0" leftRightPadding="1rem" show={this.state.settingRemoteLogging} />
        </Grid>
      </Grid>
    );
  }

  public render(): ReactNode {
    const updatedTime = this.getState()?.getStateUpdatedTimestampMillis();
    return (
      <Fragment>
        <Grid container className="overview-header-grid">
          <Grid container spacing={1} className="device-info-container">
            <Grid item>{translations.admin.texts.deviceInfo()}</Grid>
            <Grid item>&#8213;</Grid>
            <Grid item>{this.getState()?.displayName ?? this.props.device.getId()}</Grid>
          </Grid>
          <Grid container spacing={4} className="device-chip-container">
            <Grid item>{this.renderDoorStatus(this.getState()?.barrierStatus)}</Grid>
            <Grid item>{this.renderConnectionStatus(this.getState()?.connectionState)}</Grid>
            <Grid item>
              <PageChanger
                maximum={this.props.deviceList.length}
                index={this.props.deviceList.indexOf(this.props.device)}
                onDeviceSelect={(index): void => void this.props.onDeviceSelect(index)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="overview-content-grid">
          <Grid item xs={4}>
            <List>
              <ListItem>{translations.common.data.deviceId().toUpperCase()}</ListItem>
              <ListItem>{translations.deviceSettings.texts.firmwareVersion().toUpperCase()}</ListItem>
              <ListItem>{translations.admin.texts.deviceType().toUpperCase()}</ListItem>
              <ListItem>{translations.admin.texts.updatedDate().toUpperCase()}</ListItem>
              <ListItem>{translations.admin.texts.organisationId().toUpperCase()}</ListItem>
              <ListItem>{translations.admin.texts.migrationStatus().toUpperCase()}</ListItem>
              <ListItem>{translations.admin.texts.wifiRssi().toUpperCase()}</ListItem>
              <ListItem>{translations.deviceSettings.texts.resetCount().toUpperCase()}</ListItem>
              <ListItem>{translations.deviceSettings.texts.remoteLogging().toUpperCase()}</ListItem>
              <ListItem>{translations.deviceSettings.texts.stage().toUpperCase()}</ListItem>
            </List>
          </Grid>
          <Grid item xs={6}>
            <List>
              <ListItem>{this.props.device.getId()}</ListItem>
              <ListItem>{this.getState()?.firmwareVersion ?? translations.common.texts.notAvailable()}</ListItem>
              <ListItem>{this.props.device.getType()}</ListItem>
              <ListItem>
                {updatedTime ? new Date(updatedTime).toLocaleString() : translations.common.texts.notAvailable()}
              </ListItem>
              <ListItem>{this.props.device.getAttribute("organization")}</ListItem>
              <ListItem>{this.getState()?.migrationStatus ?? translations.common.texts.notAvailable()}</ListItem>
              <ListItem>{this.getState()?.wifiRssi ?? translations.common.texts.notAvailable()}</ListItem>
              <ListItem>{this.getState()?.resetCount ?? translations.common.texts.notAvailable()}</ListItem>
              <ListItem style={{ marginTop: 0, paddingTop: 0 }}>{this.renderRemoteLoggingDropdown()}</ListItem>
              <ListItem style={{ marginTop: 0, paddingTop: 0 }}>{this.renderStageDropdown()}</ListItem>
            </List>
          </Grid>
          <Grid item xs={2} className="overview-button-grid">
            <OtaUpdateButton device={this.props.device} />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}
