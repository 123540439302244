/*
 * Copyright (C) 2019 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */

import React, { Component } from "react";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { DevicePathRouterProps } from "../../types/routerprops";
import { AuthWrapper } from "@sade/data-access";
import CLOSE_ICON from "../../assets/baseline-close-24px.svg";
import MENU_ICON from "../../assets/baseline-menu-24px.svg";
import ViewAccessMethods from "../../ViewAccessMethods";
import accessControlled from "../access-control/access-controlled";
import HEADER_LOGO from "../../assets/Nice-GO-Logo.svg";
import Paths, { getPathWithRetainedParams } from "../Paths";
import { Maybe } from "../../types/aliases";
import { translations } from "../../generated/translationHelper";

interface State {
  isMenuOpen: boolean;
  firstname?: string;
}

type Props = RouteComponentProps<DevicePathRouterProps>;

const SupportAdminContent = accessControlled(React.Fragment, ViewAccessMethods.hasSupportAdminAccess);
const SupportContent = accessControlled(React.Fragment, ViewAccessMethods.hasSupportAccess);

class Header extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isMenuOpen: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.initializeFirstname();
  }

  public async initializeFirstname(): Promise<void> {
    try {
      const firstname = await AuthWrapper.getGivenName();
      this.setState({
        firstname,
      });
    } catch (error) {
      console.error("getFirstname", error);
    }
  }

  private toggleMenu = (): void => {
    this.setState((prevState: State) => ({ isMenuOpen: !prevState.isMenuOpen }));
  };

  private getRoute(path: Paths): string {
    return getPathWithRetainedParams(path, this.props);
  }

  private getFirstname(): Maybe<string> {
    const trimmedUsername = this.state.firstname?.trim();
    if (trimmedUsername && trimmedUsername.length > 0) return trimmedUsername;
  }

  private listMenuItems(onClickEnabled?: boolean): JSX.Element {
    return (
      <ul className="menu-list">
        <SupportAdminContent showLoader={false} showAccessError={false}>
          <li className="menu-item">
            <NavLink
              to={this.getRoute(Paths.ADMIN)}
              onClick={onClickEnabled ? this.toggleMenu : undefined}
              activeClassName="active"
              className="menu-item-link"
            >
              {translations.common.texts.admin()}
            </NavLink>
          </li>
        </SupportAdminContent>
        <SupportContent showLoader={false} showAccessError={false}>
          <li className="menu-item">
            <NavLink
              to={this.getRoute(Paths.SUPPORT)}
              onClick={onClickEnabled ? this.toggleMenu : undefined}
              activeClassName="active"
              className="menu-item-link"
              data-testid="admin-tab"
            >
              {translations.common.texts.support()}
            </NavLink>
          </li>
        </SupportContent>
        <SupportAdminContent showLoader={false} showAccessError={false}>
          <li className="menu-item">
            <NavLink
              to={this.getRoute(Paths.ANALYTICS)}
              onClick={onClickEnabled ? this.toggleMenu : undefined}
              activeClassName="active"
              className="menu-item-link"
            >
              {translations.common.texts.analytics()}
            </NavLink>
          </li>
        </SupportAdminContent>
        <li className="menu-item">
          <NavLink
            to={this.getRoute(Paths.USER)}
            onClick={onClickEnabled ? this.toggleMenu : undefined}
            activeClassName="active"
            className="menu-item-link"
            data-testid="user-tab"
          >
            {this.getFirstname() ?? translations.common.texts.user()}
          </NavLink>
        </li>
      </ul>
    );
  }

  public render(): JSX.Element {
    return (
      <header className="header-container col-sm-11">
        <div className="mobile-menu-container col-sm-1 col-xsm-2">
          <img
            className="menu-button"
            alt="menu"
            src={this.state.isMenuOpen ? CLOSE_ICON : MENU_ICON}
            onClick={this.toggleMenu}
          />
          <div className={this.state.isMenuOpen ? "menu-content" : "menu-content hidden"}>
            {this.state.isMenuOpen && this.listMenuItems(true)}
          </div>
        </div>
        <div className="logo-container col-md-4 col-sm-5 col-xsm-10">
          <a href="https://na.niceforyou.com/" target="_blank" rel="noopener noreferrer">
            <img className="header-logo" src={HEADER_LOGO} alt="header-logo" />
          </a>
        </div>
        <div className="menu-container col-md-8 col-sm-7">
          <div className="menu">{this.listMenuItems()}</div>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
