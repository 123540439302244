/*
 * Copyright (C) 2022 SADE Innovations Oy - All Rights Reserved
 *
 * NOTICE: This software is owned by SADE Innovations Oy and licensed under SADE Booster license.
 * All dissemination, usage, modification, copying, reproduction, selling and distribution of the
 * software and its intellectual and technical concepts are strictly forbidden without a valid license.
 * Such license can be obtained by issuing a SADE Booster License agreement from SADE Innovations Oy
 * (https://sadeinnovations.com).
 */
export class DeviceStateProperties {
    constructor(deviceProperties) {
        var _a, _b, _c, _d, _e, _f;
        this.deviceId = (_a = deviceProperties.deviceId) !== null && _a !== void 0 ? _a : null;
        this.displayName = (_b = deviceProperties.displayName) !== null && _b !== void 0 ? _b : null;
        this.otaId = (_c = deviceProperties.otaId) !== null && _c !== void 0 ? _c : null;
        this.deviceFwVersion = (_d = deviceProperties.deviceFwVersion) !== null && _d !== void 0 ? _d : null;
        this.stage = (_e = deviceProperties.stage) !== null && _e !== void 0 ? _e : null;
        this.remoteLogging = (_f = deviceProperties.remoteLogging) !== null && _f !== void 0 ? _f : null;
    }
}
